.swiper-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
}
.feedback-image {
    width: 50%;
    display: flex;
}
.feedback-image > img {
    width: 100%;
    height: 65vw;
    object-fit: cover;
    object-position: bottom;
}
.swiper-pagination {
    /* position: relative !important; */
}
.swiper-pagination-bullet {
    background-color: white !important;
    display: inline-block;
    width: 2rem;
    height: 2rem;
}
.swiper-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: var(--nav-menu-background);

}
/* change color of next 2 bullets in sequence to white*/

.swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next {
background-color: white;
}

/* change color of previous bullet to white*/

.swiper-pagination-bullet-active-prev {
background-color: white;
}
.feedback {
    display: flex;
    justify-content: center;
}
.feedback-background {
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.feedback-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.feedback-me {
    color: var(--text-color);
    margin: 2em 0.5em 0.5em 0.5em;
    text-align: center;
}
.feedbacks {
    color: white;
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: 4px;
    line-height: 1.3em;
    text-align: center;
    padding-bottom: 42px;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%); */
}
.feedback-items {
    color: white;
    display: flex;
    flex-direction: column;
}
.feedback-desc-container {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    /* background-color: rgb(255, 255, 255); */
}
.feedback-desc {
    color: rgb(49, 49, 49);
    font-size: 1.1em;
    font-weight: 400;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);  */
}
.feedback-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.feedback-item {
    text-align: center;
    background-color: whitesmoke;
    color: rgb(255, 255, 255);
    padding: 5px;
    margin: 10px;
    width: 80vw;
    max-width: 500px;
    height: max-content;
    font-size: 1rem;
    /* border: 1px solid white; */
    transition: 1s;
    border-radius: 10px;
}
.feedback-title, .feedback-type, .feedback-date {
    padding: 3px;
    font-size: 1.2em;
    font-weight: 400;
    color: rgb(70, 69, 69);
}
.feedback-date {
    font-size: 12px;
    color: grey;
}
.feedback-top-container {
    display: flex;
    flex-direction: row;
}
@media screen and (max-width: 900px) {
    .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .feedback-image {
        width: 100%;
    }
    .swiper-background {
        width: 100%;
    }
}
