.Home1 {
 width: 70vw;
}
.Home1-container {
    display: flex;
    flex-direction: row;
    margin: 4em 0em;
    width: 100%;
}
.item1 {
    display: flex;
    width: 100%;
    height: auto;
    flex: 50% 1;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
.item2 {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* padding-left: 3em; */
}
.Home1-image {
    width: 80%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    
}
.Home1-desc {
    line-height: 2em;
    letter-spacing: 1px;
    font-weight: 200;
}
.btn-reg-container {
    position: relative;
    bottom: 4em;
}

.home12,
.about1 {
    width: 100%;
    /* text-align: center; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.home-background,
.about-backgrounds {
    position: relative;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .home-background {
    position: relative;
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
} */
.home-image {
    width: 80%;
}
.home-title {
    color: white;
    width: 100%;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 73%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);    */
    animation-duration: 1s;
    animation-name: hometitle;
}
.brands-container {
    margin-top: 3rem;
    background-color: var(--nav-menu-background);
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
}
.brands-title {
    color: var(--text-color);
    margin: 4em 0.5em 0.5em 0.5em;
    padding-top: 1em;
    text-align: center;
}
.brands-text {
    font-size: 1.7rem;
    font-weight: 600;
}
@keyframes hometitle {

    /* 0% { transform: translateY(-1000px)} */
    /* 70% { transform: translateY(-10px)} */
    /* 100% { transform: translateY(73%)} */

    from {
        left: -1000px;
    }
    to {
        left: 50%;
    }
}
.btn-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto 0 auto;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn-all-old {
    text-align: center;
    margin: 10px;
    width: 70vw;
    max-width: 400px;
    font-size: var(--btn-text-size);
    border: 1px solid white;
    font-weight: 900;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);    */
    align-items: center;
  appearance: none;
  background-color: var(--nav-menu-background);
  border-radius: 25px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: var(--text-color);
  cursor: pointer;
  display: inline-flex;
  /* font-family: "JetBrains Mono",monospace; */
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* white-space: nowrap; */
  will-change: box-shadow,transform;
}


.btn-all, .btn-update {
  align-items: center;
  appearance: none;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--text-color);
  /* cursor: pointer; */
  display: inline-flex;
  font-size: 1rem;
  font-weight: bold;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin: 0.5em 0em;
}

.btn-all:active, .btn-update:active {
  border-color: #4a4a4a;
  outline: 0;
}

.btn-all:focus, .btn-update:focus {
  border-color: var(--nav-menu-background);
  outline: 0;
}

.btn-all:hover, .btn-update:hover {
  border-color: #b5b5b5;
}

.btn-all:focus:not(:active), .btn-update:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}
.textarea-update {
    align-items: center;
  appearance: none;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--text-color);
  /* cursor: pointer; */
  display: inline-flex;
  font-size: 1rem;
  font-weight: bold;
  height: 9em;
  width: 14em;
  white-space: break-spaces;
  justify-content: center;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  margin: 0.5em 0em;  
}
.btn-alls {
    text-align: center;
    margin: 10px;
    font-size: var(--btn-text-size);
    border: 1px solid white;
    font-weight: 900;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 10%);    */
    align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 25px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  /* font-family: "JetBrains Mono",monospace; */
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* white-space: nowrap; */
  will-change: box-shadow,transform;
}

.btn-services:hover {
    background-color: white;
    color: black;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    backdrop-filter: blur(5px);
    z-index: 2;
}

.about-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background-color: white;
    backdrop-filter: blur(5px);
}
.about-image {
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 30%;
    margin-top: 2em;
}
.about-title {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: 4px;
    line-height: 1.3em;
    text-align: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);    */
}
.about-desc {
    color: var(--text-color);
    max-width: 600px;
    width: 80vw;
    font-size: 1rem;
    text-align: center;
    line-height: 1.8em;
    margin-bottom: 2em;
    padding: 0 0 20px 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    word-wrap: break-word;
    -webkit-font-smooth: antialiased;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);    */
    overflow: auto;
    /* height: 100%; */
}
.btn-about {
    /* width: fit-content;
    height: auto;
    margin-top: 22px;
    padding: 0.7em 1.5em;
    font-size: 14px;
    background-color: goldenrod;
    border: 1px solid white; */
    text-align: center;
    color: var(--text-color);
    padding: 5px;
    margin: 10px;
    /* min-width: 70vw; */
    width: 80vw;
    font-size: var(--btn-text-size);
    border: 1px solid white;
    font-weight: 1000;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);    */
}
.btn-about:hover {
    background-color: white;
    color: black;
}



.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  /* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
.Home1-title {
    font-size: 2em;
    font-family: Alex brush;
    color: rgb(109, 109, 109);
    margin-bottom: 1.5em;
    font-weight: 200;
}
.firstname-lastname {
    font-size: 1.2em;
    color: var(--text-color);
    font-weight: 600;
    margin-right: 1.1em;
}
.Home1-desc {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--text-color);
}
.signature-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.signature {
    height: 5em;
}
@media (max-width: 900px) {
    .Home1 {
        width: 90vw;
    }
    .Home1-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item1 {
        text-align: center;
    }
    .home-title {
        /* top: 107%; */
        width: 100%;
        font-size: 1.5rem;
        padding-bottom: 2rem;
    }
    .btn-container {
        width: 90%;
        /* top: 130%; */
        font-size: 1rem;
        }
        .btn-services {
            text-align: center;
            background-color: rgba(0, 0, 0, 0.2);
            color: rgb(255, 255, 255);
            padding: 5px;
            margin: 10px;
            width: 80vw;
            
            font-size: 1rem;
            border: 1px solid white;
            transition: 1s;
        } 
        /* .btn-all {
            text-align: center;
            padding: 5px;
            margin: 10px;
            width: 80vw;
            
            font-size: 1rem;
            border: 1px solid white;
            transition: 1s;
        }  */
        .about-image {
            /* width: 80vw; */
            max-width: 80vw;
        }
}