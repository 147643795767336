.gallery {
    width: 70vw;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* color: white; */
}
.gallery-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.gallery-container > h1 {
    margin: 1em 0em;
    color: var(--text-color);
}
.gallery-choise {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
}
.gallery-image {
    width: 80%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.gallery-image:hover {
    
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) -20px -8px 24px 2px, rgba(17, 17, 26, 0.1) 13px -1px 20px 7px;
}
.gallery-line {
    width: 90%;
    border-top: 2px solid var(--break-line-color);
}
.images {
    max-width: 200px;
}
.gallery-all {
    margin: 3em 0em;
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
}

@media screen and (max-width: 600px) {
    .gallery {
        width: 90vw;
    }
    .gallery-choise {
        flex-direction: column;
        width: 100%;
    }
    .gallery-image {
        width: 90%;
        margin-bottom: 2em;
    }
    .gallery-all {
        width: 90vw;
    }
    .images {
        max-width: 90vw;
    }
}