.services {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    width: 90vw;
    max-width: 800px;
    margin: 1em 0em;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* position: relative; */
    color: #868484;
}
.service-rules-container {
    display: flex;
    justify-content: center;
}
.service-rules {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1em;
    /* font-size: 1.4em; */
    font-weight: bold;
    /* color: #202020; */
    max-width: 350px;
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}
.service-rules:hover {
    color: #868484;
}
.btn-rules {
    align-items: center;
    appearance: none;
    background-color: rgb(146, 143, 143);
    color: #fafafa !important;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--text-color);
    /* cursor: pointer; */
    display: inline-flex;
    font-size: 1rem;
    font-weight: bold;
    height: 2.5em;
    justify-content: center;
    line-height: 1.5;
    padding: calc(.5em - 1px) 1em;
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
    margin: 0.5em 0em;
}
.service-update-item {
    display: flex;
    flex-direction: column;
}
.service-updateBtn-container {
    display: flex;
    flex-direction: column;
}
.service-update-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.service-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.service-title {
    width: 65%;
    font-size: 1.3em;
    font-weight: bold;
    
}
.service-duration {
    font-size: 1em;
    font-weight: 800;
    margin: 0.3em;
}
.service-desc {
    margin-top: 0.5em;
    width: 65%;
    font-size: 0.8em;
    /* text-align: justify; */
}
.service-price {
    font-size: 1.3em;
    display: flex;
    align-items: center;
}
.nav-menu-lines {
    width: 100%;
    border-top: 1px solid  var(--break-line-color);
    margin: 1em 0em;
  }
  .nav-menu-lines:last-child {
    content: " ";
     width: 150px;
     height: 2px;
     /* background: red; */
     /* position: absolute; */
     /* top: 25px; */
 }
  /* .nav-menu-line:first-of-type {
    margin-top: 30vh;
  } */
.row-bold {
    font-size: 1.3em;
    font-weight: bolder;
}
.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 0.5em; */
    font-size: 1em;
    font-weight: 600;
}
.row-item {
    margin-top: 0.5em;
}
.update {
    background-color: rgb(193, 255, 193);
}
.cancel {
    background-color: rgb(255, 173, 173);
}

@media screen and (max-width: 500px) {

    .service-update-title-container {
        flex-direction: column;
    }
}