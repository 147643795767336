.contacts {
    display: flex;
    justify-content: center;
}
.contact-container {
    width: 100%;
    height: auto;
    margin: 4em 0em;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    /* border-radius: 10px; */
    background: var(--nav-menu-background);
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
}
.contact-map {
    width: 100%;
    height: auto;
    flex: 50%;
}
.contact-map iframe {
    width: 100%;
    /* height: 100%; */
    aspect-ratio: 1/1;
}
.contact-form {
    display: flex;
    width: 100%;
    height: auto;
    flex: 50% 1;
    padding: 25px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}
.contact-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.contact-form h1 {
    margin-bottom: 10px;
    color: var(--text-color);
}
.btn-default {
    align-items: center;
    appearance: none;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--text-color);
    /* cursor: pointer; */
    display: inline-flex;
    /* font-size: 1rem; */
    font-size: clamp(0.8em, 1vw, 1em);
    font-weight: bold;
    height: 2.5em;
    justify-content: center;
    line-height: 1.5;
    padding: calc(.5em - 1px) 1em;
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
    margin: 0.5em 0em;
}
.btn-label {
    background-color: rgb(245, 245, 245);
    flex-basis: 30%;
}
.btn-value {
    flex-basis: 70%;
    /* font-size: 80%; */
    /* font-size: clamp(0.5rem, 1vw + 1rem, 1rem); */
}
.hide {
    /* display: flex; */
    visibility: hidden;
    /* right: 0; */
}
@media (max-width: 500px) {
    .contacts {
        width: 100%;
        height: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    .contact-container {
        width: 100%;
    }
    .contact-form {
        padding: 0;
    }
    .contacts-title {
        color: white;
    }
      .contact-map iframe {
        border-radius: 10px;
        width: 100%;
        height: 100% !important;
        max-height: 400px;
        max-width: 400px;
        margin-bottom: 10px;
      }
     
}
@media (max-width: 360px) {
 .contact-label {
        flex-direction: column;
      }
    .hide {
        display: none;
    }
}