
.modal-background {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 4em;
    /* width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; */
}

.modal-form {
    max-width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* position: absolute; */
    /* top: -100vh; */
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
    background-color: var(--nav-menu-background);
    animation: 1s drop ease forwards;
    /* color: var(--text-color); */
    color: #6e6d6d;
    font-weight: 600;
    margin-bottom: 2em;
}
.modal-form > p {
    font-size: 1.2em;
    margin-bottom: 1em;
}
.modal-item {
    /* padding-bottom: 1em; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.modal-rules-form {
    /* border-radius: .375em; */
    /* background-color: red; */
}
.modal-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    width: 100%;
    height: auto;
    padding: 10px;
}
.modal-dropdown > span {
    padding: 10px;
    width: 100%;
}
@keyframes drop {
     from { top: -1000px}
     to { top: 5vh}
    /* 0% { transform: translateY(-1000px)}
    70% { transform: translateY(-10px)}
    100% { transform: translateY(0px)} */
}
.modal-label {
    font-size: 1.5em;
    margin-bottom: 0.2em;
}
.modal-title {
    /* font-weight: 400; */
    margin-bottom: 1em;
    font-size: 1.5rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.modal-input {
    height: 2.6em;
    border: none;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 900;
    color: var(--text-color);
    padding-left: 1em;
}
.modal-input:focus {
    outline-color: var(--nav-menu-background)
}
.modal-btn:hover {
    box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
}
.modal-paragraph {
    line-height: 1.3;
    font-size: 15px !important;
    margin-bottom: 1em !important;
}
.modal-line {
    width: 100%;
    border-top: 1px solid  var(--break-line-color);
    margin-bottom: 1em;
}
.active {
    background-color: var(--nav-menu-background);
    border: 1px solid #dbdbdb;
    border-radius: .375em;
}
.modal-close-vizitas {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
.modal-close-konsultacija {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.popup-modal, .popup-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}
.popup-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
}
.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 5px;
    max-width: 400px;
    min-width: 200px;
    width: 90%;
    max-height: 500px;
    height: 100%;
}
.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}
.popup-msg {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.popup-img {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
}
@media (max-width: 500px) {
    .modal-form {
        width: 90vw;
    }
}