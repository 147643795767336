.navbar-container {
    background-color: var(--nav-menu-background);
    height: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    align-items: center;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .navbar-dropdown {
    
    display: flex;
    margin: 0em 1em;
  }
  .navbar-logo {
    /* position: absolute; */
    height: 50px;
    filter: invert(23%) sepia(5%) saturate(17%) hue-rotate(3deg) brightness(97%) contrast(99%);
    /* left: 50%; */
    /* transform: translate(-50%, 0%); */
  }

  .navbar-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    /* position: absolute; */
    /* left: 0; */

  }
  .navbar-menu-items {
    padding: 0.5rem;
  }
  .navbar-icons {
    height: 20px;
    width: 20px;
    /* color: black; */
    background-color: transparent;
  }
  .dropdown-container {
    background-color: var(--nav-menu-background);
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dropdown {
    background-color: rgb(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: -500vh;
    /* transition: 850ms; */
    z-index: 2;
    left: 0;
    /* text-shadow: 2px 2px 4px rgb(0 0 0 / 10%); */
    
}
@keyframes dropdown {
    0% { transform: translateY(-1000px)}
    70% { transform: translateY(-10px)}
    100% { transform: translateY(0px)}
}
.open {
  top: 0;
  left: 0;
  animation: dropdown 1s ease forwards;
  /* transition: 350ms; */
}
.close {
  background-color: transparent;
  width: auto;
  position: relative;
  margin: 1rem 1rem 0 auto;
}
.nav-title, .language, .menu-contacts {
  background-color: transparent;
  font-family: var(--nav-menu-text-font);
  color: var(--text-color);
  font-size: var(--nav-menu-text-font-size);
  font-weight: var(--nav-menu-text-font-weight);
  padding: 10px;
}
.nav-title:hover {
  background-color: var(--hover-color);
  color: white;
  /* width: 95vw; */
  text-align: center;
  padding: 10px;
}
/* .nav-title {
  all: unset;
} */
.nav-title:link {
  text-decoration: none;
}

.nav-title:visited {
  text-decoration: none;
}

.nav-title:hover {
  text-decoration: none;
}

.nav-title:active {
  text-decoration: none;
}

.language:hover {
    background-color: var(--hover-color);
    color: white;
    text-align: center;
} 

.menu-contact:hover {
  background-color: var(--hover-color);
  color: white;
  text-align: center;
} 
.menu-contacts {
    display: flex;
    margin: 2rem 1rem 2rem 1rem;
    flex-direction: row;
    font-size: 1em;
    /* font-weight: 100; */
}
.menu-contact {
    /* color: var(--nav-menu-text); */
    padding: 10px;
}
.menu-contact:hover {
  background-color: var(--hover-color);
  color: white;
  /* width: 95vw; */
  text-align: center;
  /* padding: 10px; */
}
  .menu-bars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: rgb(242, 242, 242);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 2;
  
  }
  .menu-contact-icons {
    margin: -0.2rem 6px;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    list-style: none;
    height: 60px;
  }
  .nav-menu-line {
    width: 95%;
    border-top: 1px solid  var(--break-line-color);
  }
  .languages {
    display: flex;
    flex-direction: row;
  }
  .language, .contact {
    padding: 1rem;
  }

  
  .navbar-toggle {
    background-color: rgb(242, 242, 242);
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

  }
  
  .login-icon {
    margin-right: 1rem;
    height: 2rem;
  }
  .icons {
    height: 30px;
    /* color: #949494; */
  }
.search-container {
  display: flex;
  position: absolute;
  right: 3rem;
}
.searchbar-input {
  border: none;
  outline: none;
}
.searchbar {
  display: none;
}

.searchbar-open {
  display: flex;
  border-bottom: 1px solid black;
  background-color: white;
  color: black;
  z-index: 1;
  position: absolute;
  right: 0rem;
  animation: 1s search;
}
@keyframes search {
  0% { transform: rotate3d(1, 0, 0, 90deg)};
 
}

@media screen and (max-width: 900px) {
  .navbar-menu {
    display: none;
  }

}

@media screen and (max-width: 600px) {
  .navbar-dropdown {
    display: flex;
  }

  .nav-text {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95vw;
    list-style: none;
    height: 60px;
  }
  .nav-title:hover {
    background-color: var(--hover-color);
    color: white;
    width: 95vw;
    text-align: center;
    padding: 10px;
}
.menu-contact-text {
  display: none;
}
}