.Admin-container {
    display: flex;
    flex-direction: column;
}
.add-service {
    display: flex;
    flex-direction: column;
    margin: 3em 0em;
}
.add-service > input, select, option {
    height: 2.6em;
    border: none;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 900;
    color: var(--text-color);
    padding-left: 1em;
}
.add-service > textarea {
    border: none;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 900;
    color: var(--text-color);
    padding-left: 1em;
}
.uploads {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }
  label {
    max-width: 350px;
    min-width: 100px;
    box-sizing: border-box;
  }
  .break-line {
    border: 1px solid rgb(158, 158, 158);
    margin: 2em 0em;
  }