:root {
  /* button text size */
  --btn-text-size: 1.2em; 
  /* hover color */
  --hover-color: #c8c3c3;
  /* text color */
  --text-color: #868484;
  /* break line color */
  --break-line-color: #aaaaaa;

  /* --nav-menu-text: black; */
  --nav-menu-text-font: 'Montserrat';
  --nav-menu-text-font-weight: 900;
  --nav-menu-text-font-size: 1.3em;
  /* nav menu and modal background */
  --nav-menu-background: #f4f4f4;

  /* modal */
  /* --first-color: #1A73E8; */
  --first-color: #393939;
  --input-color: #80868B;
  --border-color: #DADCE0;
  /* --body-font: 'Roboto', sans-serif; */
  --normal-font-size: 1rem;
  --small-font-size: .75rem;
}
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
  }
body {
  background-color: white;
}
.App {
    display: flex;
    flex-direction: column;
    align-items: center;
}
